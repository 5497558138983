import React from 'react'
import SimpleSlider from '../SimpleSlider'
import BackgroundImageItem from '../items/BackgroundImageItem'

class TopBannerSliderSection extends React.Component {

    state = {
        banners: [
            {
                bannerWidescreen: require('../../assets/img/indigo-wine-group-robertson-winery-widescreen-banner.jpg'),
                bannerMobile: require('../../assets/img/indigo-wine-group-robertson-winery-mobile-banner.jpg'),
                altBanner: 'Indigo Wine Group Robertson Winery',
                image: require('../../assets/img/robertson-winery-logo.svg'),
                altImage: 'Robertson Winery'
            },
            {
                bannerWidescreen: require('../../assets/img/indigo-wine-group-rustenberg-widescreen-banner.jpg'),
                bannerMobile: require('../../assets/img/indigo-wine-group-rustenberg-mobile-banner.jpg'),
                altBanner: 'Indigo Wine Group Rustenberg',
                image: require('../../assets/img/rustenberg-logo.svg'),
                altImage: 'Rustenberg'
            },
            {
                bannerWidescreen: require('../../assets/img/desktop/hero-banner-franklin-tate-estates.jpg'),
                bannerMobile: require('../../assets/img/mobile/hero-banner-mobile-franklin-tate-estates.jpg'),
                altBanner: 'Indigo Wine Group Franklin Tate Estate',
                image: require('../../assets/img/desktop/hero-banner-logo-franklin-tate-estates.svg'),
                altImage: 'Franklin Tate Estate'
            },
            {
                bannerWidescreen: require('../../assets/img/desktop/hero-banner-steve-bird.jpg'),
                bannerMobile: require('../../assets/img/mobile/hero-banner-mobile-steve-bird.jpg'),
                altBanner: 'Indigo Wine Group Steve Bird',
                image: require('../../assets/img/desktop/hero-banner-logo-steve-bird.svg'),
                altImage: 'Steve Bird'
            }
        ]
    }

    renderList(){
        return this.state.banners.map((item, index) => {
            return (
                <BackgroundImageItem 
                    key={index}
                    data={item}
                />
            )
        })
    }

    render(){            
        return(
            <SimpleSlider>
                {this.renderList()}
            </SimpleSlider>
        )
    }
}

export default TopBannerSliderSection